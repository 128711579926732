let data;
export default data = {
  name: "Jonathan",

  contactEmail: "lennysgarage1@gmail.com",

  landingPageName: "Jonathan Marcantonio",

  landingPagePara: "A Software Engineer at Red Hat.",

  landingPageImage: "assets/landingPageImage.jpg",


  projects: [
    {
      id: 0,
      title: "Letterboxd Higher/Lower",
      short: "A Higher lower guessing game based on Letterboxd ratings!",
      imageSrc: "assets/letterboxd-higherlower.jpg",
      git: "https://github.com/lennysgarage/letterboxd-higherlower",
      url: "https://letterboxd-higherlower.pages.dev/"
    },
    {
      id: 1,
      title: "WikiLink",
      short: "Extend any URL using a random Wikipedia article's contents.",
      imageSrc: "assets/wikilink.png",
      git: "https://github.com/lennysgarage/WikiLink",
      url: "https://tender-shaw-eff2a3.netlify.app/"
    },
    {
      id: 2,
      title: "Plexboxd",
      short: "A Chrome extension to view Letterboxd movie ratings directly on Plex.",
      imageSrc: "assets/plexboxd.png",
      git: "https://github.com/lennysgarage/Plexboxd",
      url: "https://chromewebstore.google.com/detail/plexboxd/nofkoinidebfleimhjmilhhbbecaalhh"
    },
    {
      id: 3,
      title: "Discord Memories",
      short: "A Discord bot for exploring past memories.",
      imageSrc: "assets/maroon5.jpg",
      git: "https://github.com/lennysgarage/DiscordMemories",
      url: "https://top.gg/bot/867469054931501078"
    },
    {
      id: 4,
      title: "Letterboxd Picker",
      short: "A web tool to pick a movie off your Letterboxd watchlist.",
      imageSrc: "assets/letterboxdpicker.png",
      git: "https://github.com/lennysgarage/letterboxd-picker",
      url: "https://willowy-cucurucho-2a592f.netlify.app/"
    },
    {
      id: 5,
      title: "Letterboxd Scraper",
      short: "A web scraper tool to download a Letterboxd watchlist or other public list.",
      imageSrc: "assets/letterboxdscraper.png",
      git: "https://github.com/lennysgarage/letterboxd-scraper",
      url: "https://taupe-lollipop-099fa5.netlify.app/"
    },
    {
      id: 6,
      title: "PyJaC-IEEE",
      short: "A responsive IEEE-754 to Decimal and Decimal to IEEE-754 single precision converter.",
      imageSrc: "assets/IEEE-754.jpg",
      git: "https://github.com/lennysgarage/PyJaC-IEEE",
      url: "https://github.com/lennysgarage/PyJaC-IEEE"
    },

  ],

  // Contact Section
  contactPara:
    "I would be happy to further discuss my experiences with you, simply shoot me an email or message me on LinkedIn! :)",
  social: [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/jonathan-marcantonio/"
    },
    {
      name: "Github",
      url: "https://github.com/lennysgarage"
    },
    {
      name: "Letterboxd",
      url: "https://letterboxd.com/lennysgarage/"
    }
  ],

};